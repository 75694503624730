import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import authReducer from "./auth_reducer";
import userReducer from "./user_reducer";
import vehicleReducer from "./vehicle_reducer";
import appReducer from "./app_reducer";
import bocReducer from "./boc_reducer";
import workReducer from "./worktime_reducer";
import workorderReducer from "./workorder_reducer";

export default combineReducers({
  auth: authReducer,
  app: appReducer,
  user: userReducer,
  vehicles: vehicleReducer,
  boc: bocReducer,
  worktime: workReducer,
  workorder: workorderReducer,
  form
});
