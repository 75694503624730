import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";

// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
//import Login from "./views/Pages/Login";

import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});

class App extends Component {
  render() {
    return (
      <LocalizeProvider>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/login/:otp"
              name="Login Page"
              component={Login}
            />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </BrowserRouter>
      </LocalizeProvider>
    );
  }
}

export default App;
