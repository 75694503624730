exports.splitDate = dateObj => {
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  return { year, month, date };
};

exports.toTime = date => {
  const day = new Date(date);
  let H = day.getHours();
  if (H < 10) {
    H = "0" + H;
  }

  let mins = day.getMinutes();
  if (mins < 10) {
    mins = "0" + mins;
  }
  return `${H}:${mins}`;
};

exports.toDate = date => {
  const day = new Date(date);
  const year = day.getFullYear();
  let month = day.getMonth() + 1;
  let dateNo = day.getDate();

  month = month < 10 ? "0" + month : month;
  dateNo = dateNo < 10 ? "0" + dateNo : dateNo;

  return `${year}-${month}-${dateNo}`;
};

exports.toDateObj = date => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dateNo = date.getDate();

  month = month < 10 ? "0" + month : month;
  dateNo = dateNo < 10 ? "0" + dateNo : dateNo;

  return `${year}-${month}-${dateNo}`;
};

exports.toMonthObj = (date, i = 0) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1 + i;
  month = month < 10 ? "0" + month : month;

  return `${year}-${month}`;
};

exports.toDateFi = date => {
  const day = new Date(date);
  const year = day.getFullYear();
  let month = day.getMonth() + 1;
  let dateNo = day.getDate();

  month = month < 10 ? "0" + month : month;
  dateNo = dateNo < 10 ? "0" + dateNo : dateNo;

  return `${dateNo}.${month}.${year}`;
};

exports.toDateTime = date => {
  const day = new Date(date);

  const year = day.getFullYear();
  const month = day.getMonth() + 1;
  const dateNo = day.getDate();

  const H = day.getHours();
  let mins = day.getMinutes();
  if (mins < 10) {
    mins = "0" + mins;
  }
  return `${dateNo}.${month}.${year} ${H}:${mins}`;
};

exports.toTimeString = mins => {
  const H = Math.floor(mins / 60);
  let M = mins % 60;
  if (M < 10) M = "0" + M;
  return `${H}:${M}`;
};

exports.toDateWithDayFi = date => {
  const day = new Date(date);

  const options = {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric"
  };

  return day.toLocaleDateString("fi-FI", options);
};

exports.translateLabel = (key, lang) => {
  if (lang.code === "fi") {
    if (key === "valitse") {
      return "Valitse";
    }
    if (key === "tracktor") {
      return "Traktori";
    }
    if (key === "car") {
      return "Henkilöauto";
    }
    if (key === "coach") {
      return "Linja-auto";
    }
    if (key === "truck") {
      return "Kuorma-auto";
    }
    if (key === "van") {
      return "Pakettiauto";
    }
    if (key === "else") {
      return "Muu";
    }
  }
  if (lang.code === "en") {
    if (key === "valitse") {
      return "Choose";
    }
    if (key === "tracktor") {
      return "Tracktor";
    }
    if (key === "car") {
      return "Car";
    }
    if (key === "coach") {
      return "Coach";
    }
    if (key === "truck") {
      return "Truck";
    }
    if (key === "van") {
      return "Van";
    }
    if (key === "else") {
      return "Else";
    }
  }
};

exports.printMinMax = data => {
  const alku = data.map(d => d[0]);
  const loppu = data.map(d => d[1]);
  alku.sort((a, b) => {
    return new Date(a) - new Date(b);
  });
  loppu.sort((a, b) => {
    return new Date(a) - new Date(b);
  });

  return `${this.toTime(alku[0])}-${this.toTime(loppu[loppu.length - 1])}`;
};

exports.totalHours = data => {
  const milliseconds = data
    .map(d => {
      const alku = new Date(d[0]);
      const loppu = new Date(d[1]);
      return loppu - alku;
    })
    .reduce((acc, cur) => acc + cur, 0);

  return this.toTimeString(Math.floor(milliseconds / 60000));
};

exports.duration = (alku, loppu) => {
  const a = new Date(alku);
  const l = new Date(loppu);

  return this.toTimeString(Math.floor((l - a) / 60000));
};

exports.getHoursFromStampData = data => {
  return this.toTimeString(
    Math.floor(
      data
        .map(d => {
          const start = new Date(d.start);
          const stop = new Date(d.stop);
          return stop - start;
        })
        .reduce((acc, cur) => acc + cur, 0) / 60000
    )
  );
};

exports.sortByDate = (field, data, dir = "desc") => {
  const d = dir === "desc" ? -1 : 1;

  data.sort((a, b) => {
    return d * new Date(a[field]) - d * new Date(b[field]);
  });
  return data;
};

exports.sortByName = (field, data, dir = "desc") => {
  const d = dir === "desc" ? 1 : -1;

  data.sort((a, b) => {
    if (a[field] > b[field]) {
      return d;
    }
    if (a[field] < b[field]) {
      return -1 * d;
    } else return d;
  });
  return data;
};

exports.sortByNameSimple = (data, dir = "desc") => {
  const d = dir === "desc" ? 1 : -1;

  data.sort((a, b) => {
    if (String(a).toLowerCase() > String(b).toLowerCase()) {
      return d;
    }
    if (String(a).toLowerCase() < String(b).toLowerCase()) {
      return -1 * d;
    } else return d;
  });
  return data;
};
