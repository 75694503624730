import {
  GET_WORKORDER_CUSTOMERS,
  ADD_WORKORDER_CUSTOMER,
  DELETE_WORKORDER_CUSTOMER,
  GET_WORKORDERS,
  ADD_WORKORDER,
  UPDATE_WORKORDERS,
  DELETE_WORKORDER,
  WORKORDER_TAKEN
} from "../actions/types";

const INITIAL_STATE = {
  customers: [],
  workorders: [],
  names: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WORKORDER_CUSTOMERS:
      return { ...state, customers: action.payload };
    case ADD_WORKORDER_CUSTOMER:
      return { ...state, customers: [...state.customers, action.payload] };
    case DELETE_WORKORDER_CUSTOMER:
      return { ...state, customers: state.customers.filter(c => c.name !== action.payload) };
    case GET_WORKORDERS:
      return { ...state, workorders: action.payload.w, names: action.payload.u };
    case UPDATE_WORKORDERS:
      return { ...state, workorders: action.payload };
    case ADD_WORKORDER:
      return { ...state, workorders: [...state.workorders, action.payload] };
    case DELETE_WORKORDER:
      return { ...state, workorders: state.workorders.filter(w => w._id !== action.payload) };
    case WORKORDER_TAKEN:
      return {
        ...state,
        workorders: state.workorders.map(w => {
          if (w._id === action.payload._id) {
            return action.payload;
          }
          return w;
        })
      };
    default:
      return { ...state };
  }
}
