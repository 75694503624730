import { GET_USERS, SET_COMPANY, RESET_USER, SAVE_BOC_SETTINGS, UPDATE_USER } from "../actions/types";

const INITIAL_STATE = {
  users: [],
  company: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USERS: {
      const users = action.payload.map(user => {
        return {
          fname: user.fname,
          lname: user.lname,
          email: user.email,
          id: user._id,
          uid: user.id,
          picture: user.picture,
          permissions: user.permissions,
          appAccess: user.appAccess,
          endDate: user.endDate,
          removed: user.removed,
          appAdmin: user.appAdmin,
          accessLevel: user.accessLevel,
          subtask: user.subtask,
          subtasktimeStart: user.subtasktimeStart,
          subtasktimeStampId: user.subtasktimeStampId,
          task: user.task,
          tasktimeStampId: user.tasktimeStampId,
          tasktimeStart: user.tasktimeStart,
          worktimeStampId: user.worktimeStampId,
          worktimeStart: user.worktimeStart,
          worktimeStartLocation: user.worktimeStartLocation,
          tasktimeStartLocation: user.tasktimeStartLocation,
          subtasktimeStartLocation: user.subtasktimeStartLocation
        };
      });
      return { ...state, users };
    }
    case SET_COMPANY: {
      return {
        ...state,
        company: {
          ...action.payload.company,
          BOCSettings: {
            ...state.BOCSettings,
            ...action.payload.company.BOCSettings,
            kuormakirjat: action.payload.kuormakirjat
          }
        }
      };
    }
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(u => {
          const user =
            u.uid !== action.payload.id
              ? u
              : {
                  fname: action.payload.fname,
                  lname: action.payload.lname,
                  email: action.payload.email,
                  id: action.payload._id,
                  uid: action.payload.id,
                  picture: action.payload.picture,
                  permissions: action.payload.permissions,
                  appAccess: action.payload.appAccess,
                  endDate: action.payload.endDate,
                  removed: action.payload.removed,
                  appAdmin: action.payload.appAdmin,
                  accessLevel: action.payload.accessLevel,
                  subtask: action.payload.subtask,
                  subtasktimeStart: action.payload.subtasktimeStart,
                  subtasktimeStampId: action.payload.subtasktimeStampId,
                  task: action.payload.task,
                  tasktimeStampId: action.payload.tasktimeStampId,
                  tasktimeStart: action.payload.tasktimeStart,
                  worktimeStampId: action.payload.worktimeStampId,
                  worktimeStart: action.payload.worktimeStart,
                  worktimeStartLocation: action.payload.worktimeStartLocation,
                  tasktimeStartLocation: action.payload.tasktimeStartLocation,
                  subtasktimeStartLocation: action.payload.subtasktimeStartLocation
                };
          return user;
        })
      };
    case RESET_USER:
      return { ...INITIAL_STATE };
    case SAVE_BOC_SETTINGS:
      return {
        ...state,
        company: {
          ...state.company,
          BOCSettings: {
            ...state.company.BOCSettings,
            scaleControlLimit: action.payload.scaleControlLimit,
            listUsers: action.payload.listUsers
          }
        }
      };
    default:
      return { ...state };
  }
}
