import {
  CLEAR_VEHICLE_ALERT,
  VEHICLE_ERROR,
  GET_VEHICLES,
  NEW_VEHICLE,
  GET_VEHICLES_INFO,
  CLEAR_FUEL_ALERT,
  FUEL_ERROR,
  NEW_FUEL,
  GET_FUEL_TYPES,
  DELETE_FUEL,
  REMOVE_VEHICLE,
  GET_FILLS,
  GET_STATIONS,
  NEW_STATION,
  DELETE_STATION,
  RESET_VEHICLE,
  REMOVE_FILL
} from "../actions/types";

const INITIAL_STATE = {
  taskAddError: "",
  vehicleAddError: "",
  vehicleDetails: [],
  fuelAddError: "",
  fuels: [],
  refills: [],
  stations: [],
  vehicles: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VEHICLE_ERROR:
      return { ...state, vehicleAddError: action.payload };
    case FUEL_ERROR:
      return { ...state, fuelAddError: action.payload };
    case CLEAR_VEHICLE_ALERT:
      return { ...state, vehicleAddError: "" };
    case CLEAR_FUEL_ALERT:
      return { ...state, fuelAddError: "" };
    case GET_VEHICLES:
      return { ...state, vehicles: action.payload };
    case GET_STATIONS:
      return { ...state, stations: action.payload };
    case GET_FUEL_TYPES:
      return { ...state, fuels: action.payload, fuelAddError: "" };
    case NEW_VEHICLE: {
      const current = state.vehicleDetails;
      current.push(action.payload);
      return { ...state, vehicleDetails: current, fuelAddError: "" };
    }
    case REMOVE_VEHICLE:
      const current = state.vehicleDetails;
      current.map(c => {
        if (c.rek !== action.payload) return c;
        else {
          c.active = false;
          return c;
        }
      }); //[action.payload].active = false;
      return { ...state, vehicleDetails: current };
    case NEW_FUEL: {
      const current = state.fuels;
      current.push(action.payload);
      return { ...state, fuels: current, fuelAddError: "" };
    }
    case NEW_STATION: {
      const current = state.stations;
      current.push(action.payload);
      return { ...state, stations: current, fuelAddError: "" };
    }
    case GET_VEHICLES_INFO:
      return { ...state, vehicleDetails: action.payload };
    case DELETE_FUEL: {
      const current = state.fuels;
      const fuels = current.filter(fuel => fuel !== action.payload);
      return { ...state, fuels, fuelAddError: "" };
    }
    case DELETE_STATION: {
      const current = state.stations;
      const stations = current.filter(station => station !== action.payload);
      return { ...state, stations, fuelAddError: "" };
    }
    case GET_FILLS:
      return { ...state, refills: action.payload };
    case REMOVE_FILL: {
      const current = state.refills;
      const filtered = current.filter(b => b._id !== action.payload);
      return { ...state, refills: filtered };
    }
    case RESET_VEHICLE:
      return { ...INITIAL_STATE };
    default:
      return { ...state };
  }
}
