export const API_URL = "https://api.pkyvalvonta.fi";
//export const API_URL = "http://192.168.71.101:3050";

//APP ACTIONS
export const SHOW_MODAL = "show_modal";
export const HIDE_MODAL = "hide_modal";
export const LOADING = "loading";
export const LOADED = "loaded";
export const SET_SUCCESS_MESSAGE = "set_success_message";
export const SET_ERROR_MESSAGE = "set_error_message";
export const RESET_SUCCESS_MESSAGE = "reset_success_message";
export const RESET_ERROR_MESSAGE = "reset_error_message";
export const SHOW_NESTED_MODAL = "show_nested_modal";
export const HIDE_NESTED_MODAL = "hide_nested_modal";

//AUTH ACTIONS
export const LOGIN_SUCCESS = "login_success";
export const AUTH_USER = "auth_user";
export const AUTH_ERROR = "auth_error";
export const LOGOUT = "logout";
export const OTP_LOGIN_START = "otp_login_start";
export const OTP_LOGIN_STOP = "otp_login_stop";
export const REFRESH_START = "refresh_start";
export const REFRESH_STOP = "refresh_stop";
export const SET_TIMESTAMP = "set_timestamp";

//USERS ACTIONS
export const GET_USERS = "get_users";
export const SET_COMPANY = "set_company";
export const UPDATE_USER = "update_user";

//DASHBOARD ACTIONS
export const SET_DASHBOARD_STATE = "set_dashboard_state";

// export const TASKS_LOADING = "tasks_loading";
// export const TASKS_LOADED = "tasks_loaded";
// export const TASK_ERROR = "task_error";
// export const CLEAR_TASK_ALERT = "clear_task_alert";
// export const DESELECT_TASK = "deselect_task";
// export const NEW_SUBTASK = "new_subtask";
// export const REMOVE_SUBTASK = "remove_subtask";
// export const DESELECT_REPORT_TASK = "deselect_report_task";

// export const DESELECT_EMPLOYEE = "deselect_employee";

//VEHICLE ACTIONS
export const REMOVE_VEHICLE = "remove_vehicle";
export const CLEAR_VEHICLE_ALERT = "clear_vehicle_alert";
export const VEHICLE_ERROR = "vehicle_error";
export const VEHICLES_LOADING = "vehicles_loading";
export const NEW_VEHICLE = "new_vehicle";
export const VEHICLES_LOADED = "vehicles_loaded";
export const GET_VEHICLES = "get_vehicles";
export const GET_VEHICLES_INFO = "get_vehicles_info";
export const DESELECT_REPORT_VEHICLE = "deselect_report_vehicle";
export const CLEAR_FUEL_ALERT = "clear_fuel_alert";
export const FUEL_ERROR = "fuel_error";
export const NEW_FUEL = "new_fuel";
export const GET_FUEL_TYPES = "get_fuel_types";
export const DELETE_FUEL = "delete_fuel";
export const GET_FILLS = "get_fills";
export const GET_STATIONS = "get_stations";
export const NEW_STATION = "new_station";
export const DELETE_STATION = "delete_station";
export const REMOVE_FILL = "remove_fill";

//LOAD ACTIONS
export const SET_CUSTOMERS = "set_customers";
export const NEW_CUSTOMER = "new_customer";
export const DELETE_CUSTOMER = "delete_customers";
export const SET_DELIVERY_SITES = "set_delivery_sites";
export const SITES_LOADING = "sites_loading";
export const DELETE_DELIVERY_SITE = "delete_delivery_site";
export const NEW_SITE = "new_site";
export const DELETE_TYPE = "delete_type";
export const SET_TYPES = "set_types";
export const NEW_TYPE = "new_type";
export const GET_BOCS = "get_bocs";
export const BOC_UPDATE = "boc_update";
export const REMOVE_BOC = "remove_boc";
export const SAVE_BOC_SETTINGS = "save_boc_settings";
export const GET_BATCHES = "get_batches";
export const SET_ASIAKKAAT = "set_asiakkaat";
export const SET_LAHTOPAIKAT = "set_lahtopaikat";
export const UUSI_TOIMITUSPAIKKA = "uusi_toimituspaikka";
export const SET_LAJIT = "set_lajit";
export const UUSI_LAJI = "uusi_laji";
export const SET_TOIMITUSPAIKAT = "set_toimituspaikat";
export const POISTA_ASIAKAS = "poista_asiakas";
export const UUSI_ASIAKAS = "uusi_asiakas";
export const POISTA_TOIMITUSPISTE = "poista_toimituspiste";
export const POISTA_LAJI = "poista_laji";
export const GET_ERAT = "get_erat";
export const ERA_LISATTY = "era_lisatty";
export const ERA_POISTETTU = "era_poistettu";
export const GET_KUORMAKIRJAT = "get_kuormakirjat";
export const GET_KUORMAKIRJA_KAYTTAJAT = "get_kuormakirja_kayttajat";
export const UUSI_KUORMAKIRJA_IO = "uusi_kuormakirja_io";
export const POISTA_KUORMAKIRJA = "poistakuormakirja";
export const SET_SAAPUMISPISTEET = "set_saapumispisteet";
export const UUSI_LAHTOPISTE = "uusi_lahtopiste";
export const POISTA_LAHTOPISTE = "poista_lahtopiste";
export const POISTA_SAAPUMISPISTE = "poista_saapumispiste";
export const GET_TARKENTEET = "get_tarkenteet";
export const UUSI_TARKENNE = "uusi_tarkenne";
export const POISTA_TARKENNE = "poista_tarkenne";

//WORKTIME ACTIONS
export const GET_TASKS = "get_tasks";
export const NEW_TASK = "new_task";
export const WORKTIME_DATA_UPDATE = "worktime_data_update";
export const WORKTIME_DATA_UPDATE_RESET = "worktime_data_reset";
export const WORKTIME_START = "worktime_start";
export const WORKTIME_STOP = "worktime_stop";
export const TASKTIME_START = "tasktime_start";
export const TASKTIME_STOP = "tasktime_stop";
export const SUBTASKTIME_START = "subtasktime_start";
export const SUBTASKTIME_STOP = "subtasktime_stop";

//RESETS
export const RESET_AUTH = "reset_auth";
export const RESET_APP = "reset_app";
export const RESET_BOC = "reset_boc";
export const RESET_USER = "reset_user";
export const RESET_VEHICLE = "reset_vehicle";
export const RESET_WORKTIME = "reset_worktime";

//WORKORDER ACTIONS
export const GET_WORKORDER_CUSTOMERS = "get_workorder_customers";
export const ADD_WORKORDER_CUSTOMER = "add_workorder_customer";
export const DELETE_WORKORDER_CUSTOMER = "delete_workorder_customer";
export const GET_WORKORDERS = "get_workorders";
export const ADD_WORKORDER = "add_workorder";
export const UPDATE_WORKORDERS = "update_workorders";
export const DELETE_WORKORDER = "delete_workorder";
export const WORKORDER_TAKEN = "workorder_taken";
