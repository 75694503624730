import {
  SHOW_MODAL,
  SHOW_NESTED_MODAL,
  HIDE_MODAL,
  HIDE_NESTED_MODAL,
  LOADING,
  LOADED,
  SET_SUCCESS_MESSAGE,
  RESET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  RESET_ERROR_MESSAGE,
  RESET_APP
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  modal: "",
  nestedModal: "",
  success: "",
  error: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case LOADED:
      return { ...state, loading: false };
    case SHOW_MODAL:
      return { ...state, modal: action.payload };
    case SHOW_NESTED_MODAL:
      return { ...state, nestedModal: action.payload };
    case HIDE_MODAL:
      return { ...state, modal: "" };
    case HIDE_NESTED_MODAL:
      return { ...state, nestedModal: "" };
    case SET_SUCCESS_MESSAGE:
      return { ...state, success: action.payload };
    case RESET_SUCCESS_MESSAGE:
      return { ...state, success: "" };
    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    case RESET_ERROR_MESSAGE:
      return { ...state, error: "" };
    case RESET_APP:
      return { ...INITIAL_STATE };
    default:
      return { ...state };
  }
}
