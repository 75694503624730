import {
  SET_CUSTOMERS,
  NEW_CUSTOMER,
  DELETE_CUSTOMER,
  SET_DELIVERY_SITES,
  DELETE_DELIVERY_SITE,
  NEW_SITE,
  DELETE_TYPE,
  SET_TYPES,
  NEW_TYPE,
  GET_BOCS,
  RESET_BOC,
  BOC_UPDATE,
  REMOVE_BOC,
  GET_BATCHES,
  SET_LAHTOPAIKAT,
  UUSI_TOIMITUSPAIKKA,
  SET_LAJIT,
  UUSI_LAJI,
  SET_ASIAKKAAT,
  SET_TOIMITUSPAIKAT,
  POISTA_ASIAKAS,
  UUSI_ASIAKAS,
  POISTA_TOIMITUSPISTE,
  POISTA_LAJI,
  GET_ERAT,
  ERA_LISATTY,
  ERA_POISTETTU,
  GET_KUORMAKIRJAT,
  GET_KUORMAKIRJA_KAYTTAJAT,
  UUSI_KUORMAKIRJA_IO,
  POISTA_KUORMAKIRJA,
  SET_SAAPUMISPISTEET,
  UUSI_LAHTOPISTE,
  POISTA_LAHTOPISTE,
  POISTA_SAAPUMISPISTE,
  GET_TARKENTEET,
  UUSI_TARKENNE,
  POISTA_TARKENNE
} from "../actions/types";

import { sortByDate } from "../utils/functions";

const INITIAL_STATE = {
  error: "",
  customers: [],
  deliverySites: [],
  saapumispisteet: [],
  types: [],
  loading: false,
  bocs: [],
  batchDetails: [],
  updated: false,
  asiakkaat: [],
  toimituspaikat: [],
  lahtopisteet: [],
  lajit: [],
  erat: [],
  kuormakirjat: [],
  kuormakirjaKayttajat: [],
  uusi_kuormakirja_io: false,
  tarkenteet: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CUSTOMERS:
      return { ...state, customers: action.payload, error: "", updated: false };
    case SET_ASIAKKAAT:
      return { ...state, asiakkaat: action.payload, error: "", updated: false };
    case SET_DELIVERY_SITES:
      return { ...state, deliverySites: action.payload, error: "", updated: false };
    case SET_TOIMITUSPAIKAT:
      return { ...state, toimituspaikat: action.payload, error: "", updated: false };
    case SET_SAAPUMISPISTEET:
      return { ...state, saapumispisteet: action.payload, error: "", updated: false };
    case SET_LAHTOPAIKAT:
      return { ...state, lahtopisteet: action.payload, error: "", updated: false };
    case UUSI_LAHTOPISTE:
      return { ...state, lahtopisteet: [...state.lahtopisteet, action.payload] };
    case SET_TYPES: {
      return { ...state, types: action.payload, error: "", updated: false };
    }
    case SET_LAJIT: {
      return { ...state, lajit: action.payload, error: "", updated: false };
    }
    case NEW_CUSTOMER: {
      const current = state.customers;
      current.push(action.payload);
      return { ...state, customers: current, error: "", updated: false };
    }
    case UUSI_ASIAKAS: {
      return { ...state, asiakkaat: [...state.asiakkaat, action.payload], updated: false };
    }
    case DELETE_CUSTOMER: {
      const current = state.customers;
      const customers = current.filter(company => company !== action.payload);
      return { ...state, customers, updated: false };
    }
    case POISTA_ASIAKAS: {
      return {
        ...state,
        asiakkaat: state.asiakkaat.filter(
          a => a.nimi !== action.payload.nimi || a.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    }
    case NEW_SITE: {
      const current = state.deliverySites;
      current.push(action.payload);
      return { ...state, deliverySites: current, error: "", updated: false };
    }
    case UUSI_TOIMITUSPAIKKA: {
      if (action.payload.endpoint === "lisaaToimituspiste") {
        return {
          ...state,
          toimituspaikat: [...state.toimituspaikat, action.payload.data],
          updated: false
        };
      }
      if (action.payload.endpoint === "lisaaSaapumispiste") {
        return {
          ...state,
          saapumispisteet: [...state.saapumispisteet, action.payload.data],
          updated: false
        };
      } else
        return {
          ...state
        };
    }
    case POISTA_TOIMITUSPISTE: {
      return {
        ...state,
        toimituspaikat: state.toimituspaikat.filter(
          a => a.nimi !== action.payload.nimi || a.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    }
    case POISTA_LAHTOPISTE: {
      return {
        ...state,
        lahtopisteet: state.lahtopisteet.filter(
          a => a.nimi !== action.payload.nimi || a.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    }
    case POISTA_SAAPUMISPISTE: {
      return {
        ...state,
        saapumispisteet: state.saapumispisteet.filter(
          a => a.nimi !== action.payload.nimi || a.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    }

    case DELETE_DELIVERY_SITE: {
      const current = state.deliverySites;
      const deliverySites = current.filter(site => site !== action.payload);
      return { ...state, deliverySites, updated: false };
    }
    case NEW_TYPE: {
      const current = state.types;
      current.push(action.payload);
      return { ...state, types: current, error: "", updated: false };
    }
    case UUSI_LAJI: {
      return { ...state, lajit: [...state.lajit, action.payload], updated: false };
    }
    case DELETE_TYPE: {
      const current = state.types;
      const types = current.filter(type => type !== action.payload);
      return { ...state, types, updated: false };
    }
    case POISTA_LAJI: {
      return {
        ...state,
        lajit: state.lajit.filter(
          a => a.nimi !== action.payload.nimi || a.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    }

    case GET_BOCS: {
      return { ...state, bocs: action.payload, updated: false };
    }
    case GET_KUORMAKIRJAT: {
      return {
        ...state,
        kuormakirjat: sortByDate("paivamaara", action.payload),
        updated: false,
        uusi_kuormakirja_io: false
      };
    }

    case BOC_UPDATE: {
      return { ...state, updated: action.payload };
    }
    case REMOVE_BOC: {
      const current = state.bocs;
      const filtered = current.filter(b => b._id !== action.payload);
      return { ...state, bocs: filtered, updated: false };
    }
    case GET_BATCHES: {
      return { ...state, batchDetails: action.payload, updated: false };
    }
    case RESET_BOC:
      return { ...INITIAL_STATE };
    case GET_ERAT:
      return { ...state, erat: action.payload, updated: false };
    case ERA_LISATTY:
      return { ...state, erat: [...state.erat, action.payload], updated: false };
    case ERA_POISTETTU:
      return {
        ...state,
        erat: state.erat.filter(
          e =>
            e.eranimi !== action.payload.eranimi ||
            e.asiakas !== action.payload.asiakas ||
            e.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    case GET_KUORMAKIRJA_KAYTTAJAT:
      return { ...state, kuormakirjaKayttajat: action.payload, updated: false };
    case GET_TARKENTEET:
      return { ...state, tarkenteet: action.payload, updated: false };
    case UUSI_TARKENNE: {
      return { ...state, tarkenteet: [...state.tarkenteet, action.payload], updated: false };
    }

    case UUSI_KUORMAKIRJA_IO:
      return { ...state, uusi_kuormakirja_io: true };
    case POISTA_KUORMAKIRJA:
      return { ...state, kuormakirjat: state.kuormakirjat.filter(k => k._id !== action.payload) };
    case POISTA_TARKENNE: {
      return {
        ...state,
        tarkenteet: state.tarkenteet.filter(
          a => a.nimi !== action.payload.nimi || a.kuormakirja !== action.payload.kuormakirja
        ),
        updated: false
      };
    }
    default:
      return { ...state };
  }
}
