import {
  LOGIN_SUCCESS,
  AUTH_ERROR,
  LOGOUT,
  OTP_LOGIN_START,
  OTP_LOGIN_STOP,
  REFRESH_START,
  REFRESH_STOP,
  SET_TIMESTAMP,
  WORKTIME_START,
  WORKTIME_STOP,
  TASKTIME_START,
  TASKTIME_STOP,
  SUBTASKTIME_START,
  SUBTASKTIME_STOP
} from "../actions/types";

const INITIAL_STATE = {
  uid: "",
  error: "",
  authenticated: false,
  accessLevel: "",
  message: "",
  firstName: "",
  lastName: "",
  id: "",
  company: "",
  companyCode: "",
  cid: "",
  loading: false,
  permissions: {},
  picture: "",
  otpLogin: false,
  timestamp: "",
  refresh: false,
  token: "",
  defaultCulture: "fi",
  subtask: null,
  subtasktimeStart: null,
  task: null,
  tasktimeStampId: null,
  tasktimeStart: null,
  worktimeStampId: null,
  worktimeStart: null,
  vehicle: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OTP_LOGIN_START: {
      return { ...state, otpLogin: true };
    }
    case OTP_LOGIN_STOP: {
      return { ...state, otpLogin: false };
    }
    case LOGIN_SUCCESS: {
      const {
        fname,
        lname,
        company,
        companyCode,
        accessLevel,
        permissions,
        picture,
        uid,
        defaultCulture,
        subtask,
        subtasktimeStart,
        subtasktimeStampId,
        task,
        tasktimeStampId,
        tasktimeStart,
        worktimeStampId,
        worktimeStart,
        vehicle
      } = action.payload.user;

      return {
        ...state,
        uid,
        authenticated: true,
        token: action.payload.token,
        error: "",
        loading: false,
        fname,
        lname,
        company,
        companyCode,
        accessLevel: parseInt(accessLevel),
        permissions,
        picture,
        timestamp: new Date(action.payload.timestamp),
        defaultCulture,
        subtask,
        subtasktimeStart,
        subtasktimeStampId,
        task,
        tasktimeStampId,
        tasktimeStart,
        worktimeStampId,
        worktimeStart,
        vehicle
      };
    }
    case AUTH_ERROR: {
      return {
        ...state,
        authenticated: false,
        loading: false,
        error: action.payload
      };
    }
    case LOGOUT:
      return { ...INITIAL_STATE };
    case REFRESH_START:
      return { ...state, refresh: true };
    case REFRESH_STOP:
      return { ...state, refresh: false };
    case SET_TIMESTAMP:
      return {
        ...state,
        refresh: false,
        timestamp: new Date(action.payload.timestamp),
        token: action.payload.token
      };
    case WORKTIME_START:
      return {
        ...state,
        worktimeStampId: action.payload.id,
        worktimeStart: action.payload.stamp
      };
    case WORKTIME_STOP:
      return {
        ...state,
        worktimeStampId: null,
        worktimeStart: null,
        vehicle: null,
        tasktimeStampId: null,
        tasktimeStart: null,
        task: null,
        subtask: null,
        subtasktimeStampId: null,
        subtasktimeStart: null
      };
    case TASKTIME_START:
      return {
        ...state,
        tasktimeStampId: action.payload.id,
        tasktimeStart: action.payload.stamp,
        vehicle: action.payload.vehicle,
        task: action.payload.task
      };
    case TASKTIME_STOP:
      return {
        ...state,
        vehicle: null,
        tasktimeStampId: null,
        tasktimeStart: null,
        task: null,
        subtask: null,
        subtasktimeStampId: null,
        subtasktimeStart: null
      };
    case SUBTASKTIME_START:
      return {
        ...state,
        subtask: action.payload.subtask,
        subtasktimeStart: action.payload.stamp,
        subtasktimeStampId: action.payload.subtasktimeStampId
      };
    case SUBTASKTIME_STOP:
      return {
        ...state,
        subtask: null,
        subtasktimeStampId: null,
        subtasktimeStart: null
      };
    default:
      return { ...state };
  }
}
