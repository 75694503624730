import {
  GET_TASKS,
  NEW_TASK,
  RESET_WORKTIME,
  WORKTIME_DATA_UPDATE,
  WORKTIME_DATA_UPDATE_RESET
} from "../actions/types";

const INITIAL_STATE = {
  tasks: [],
  updated: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TASKS:
      return { ...state, tasks: action.payload, updated: false };
    case NEW_TASK: {
      const current = state.tasks;
      current.push({
        name: action.payload.name,
        subTasks: action.payload.subTasks,
        _id: action.payload._id,
        updated: false
      });
      return { ...state, tasks: current, updated: false };
    }
    case WORKTIME_DATA_UPDATE:
      return { ...state, updated: true };
    case WORKTIME_DATA_UPDATE_RESET:
      return { ...state, updated: false };

    case RESET_WORKTIME:
      return { ...INITIAL_STATE };

    default:
      return { ...state };
  }
}
